import React from "react";
import { Page } from "../../../components/Page";
import { Title } from "../../../components/Title";
import { Content } from "../../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const HelsinkiPage = () => (
  <Page language="sv" page="Helsingfors">
    <Row>
      <Col>
        <Title>
          Så här anmäler du dig som mottagare av varnings&shy;meddelanden:
        </Title>

        <Content style={{ color: "var(--color-brick)"}}>
          Tyvärr kommer SMS-tjänsten att vara ur drift nästa vinter, men fortsätter som vanligt nästa vinter.
        </Content>

        <Content>
          Skicka ett SMS till numret 16130 från den telefon till vilken du vill
          att varningen ska skickas:
        </Content>

        <Content as="ol">
          <li>
            Börja meddelandet med orden <b>varning helsingfors</b>
          </li>
          <li>
            Avsluta meddelandet antingen med <b>genast, 6, 7, 8 eller 9</b>,
            beroende på om du vill ta emot en varning om halka som har uppstått
            på natten genast då varningen har utfärdats, kl. 6, kl. 7, kl. 8
            eller kl. 9
          </li>
          <li>
            Om du till exempel vill ha en varning om halka som har uppstått på
            natten på morgonen kl. 7 skickar du meddelandet:{" "}
            <b>varning helsingfors 7</b>.
          </li>
        </Content>

        <Content>
          Din anmälan gäller även för kommande vintrar. Om du inte längre vill
          ta del av tjänsten, skicka SMS:et <b>varning stäng</b> till nummer{" "}
          <b>16130</b>.
        </Content>
      </Col>
    </Row>
  </Page>
);

export default HelsinkiPage;
